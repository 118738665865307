import React from "react"
import { Waypoint } from 'react-waypoint';
import { Link } from 'gatsby'

import {handleWaypointIn} from "../utilities/waypoint"
import Layout from "../components/layout/Layout"
import ContactSection from "../components/contact/ContactSection"
import SEO from "../components/seo"

const WebDevelopment = () => {
  
  return (
    <div className="web-development">
        <Layout>
            <SEO 
                title="Web Development"
                description="Chris Bartlett: Web Development | Wordpress | Gatsby | Rails | React" 
            />	         
            <section className="page-header">
                <Waypoint onEnter={() => handleWaypointIn('title-text', 'swing-in-top-fwd')}>         
                    <div id="title-text">             
                        <h1>Web Development</h1> 
                    </div>    
                </Waypoint>                
            </section> 

            <section className="content">
                <div className="gray-bg">
                    <Waypoint onEnter={() => handleWaypointIn('web-text')}>         
                        <div id="web-text">              
                            <h2>WHAT IS WEB DEVELOPMENT AND WHY IS IT SO IMPORTANT?</h2>

                
                            <p>Web development refers to the coding of the website. An analogy would be the building of a house. Web development is the structure – the bricks and mortar. Web design would be the decoration and finish to make it look nice.</p>

                            <p>Just like building a house there are many different ways to do it. All of them can be good if done well. I specialise in WordPress, Gatsby, Next.js web and ecommerce websites such as Woocommerce or Shopify. These are built using PHP7, HTML5, CSS3 and React/Javascript. I also do Ruby on Rails custom websites as well as standard HTML5 static websites. </p>
                            
                            <p>I'm happy to take your design that was made by someone else and turn it into a fully functioning website. Please contact me for more details on web development build only.</p>
                            
                            <p>Website Development is very important because if built badly, just like a house, you will suffer severe consequences. Functionality might not work as you would like, visitors could experience a broken site and go elsewhere and basically your site will just not be good enough. It might look nice but not work and give a bad user experience.</p>
                            <p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
                        </div>    
                    </Waypoint>  
                </div>
                <div className="white-bg">
                    <Waypoint onEnter={() => handleWaypointIn('standard-text')}>         
                        <div id="standard-text">                    
                            <h2>WEB DEVELOPMENT BUILT TO A HIGH STANDARD</h2>
                            
                            <p>I have over 20 years of experience and love PHP, HTML5, CSS3, Javascript and Ruby on Rails. I have worked across the globe in agencies and corporations. </p>

                            <p>During my career I have worked on many different sized sites from one page websites, small business or informational sites through to large ecommerce and corporate sites. Creating both internal systems and external web sites. From basic text sites to dynamic database-driven websites. </p>
                            
                            <p>As a web developer it is my job to ensure I always keep in mind security, usability, user experience, interactivity, search engine optimisation (SEO) and accessibility when building all of my websites to give you sound piece of mind that your site will not only look good but will work great too and above all else will be secure.</p>
                            
                            <p>Your site will be checked to work on multiple platforms and devices from windows to macs, from desktops to laptops to tablet to mobile phones.  </p>  
                            <p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>   
                        </div>    
                    </Waypoint>  
                </div>

                <div className="gray-bg">
                    <Waypoint onEnter={() => handleWaypointIn('wordpress-text')}>         
                        <div id="wordpress-text">                    
                            <h2>WORDPRESS WEB DEVELOPMENT</h2>

                            <p>In my time I have used several different CMSs, but at the moment I recommend using WordPress for various reasons. It’s incredibly easy to use, it has a wealth of plugins and can be easily customised. It powers 32% of the web and be used for a whole range of different sites.</p>

                            <p>One of the reasons I like using wordpress is ease of use. And for those who want to use page builders it’s even easier when combined with the Elementor plugin. When used with wordfence it is also very secure. WordPress is great for SEO and is so much more than just a blogging platform. It’s highly customisable and we can tailor wordpress to use for any site. For people who want fast sites and won't be changing content much then I recommend having a custom theme built.</p>

                            <p>I can also take over the hosting and maintenance of your current wordpress website and update any functionality that you may require.</p>

                            <p>I also have much experience in connecting to many different types of third-party system from CRMs to ERPs, from Salesforce to a custom solution, via APIs. I can connect them to your website through custom web development.</p>

                            <p>Because we are using open source technology, along with some paid for plugins, you know hundreds of people have worked on them and they function very well. The less customisation required the cheaper the total price for you.</p> 
                            <p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
                            
                        </div>    
                    </Waypoint>  
                </div>
     
                <div className="white-bg">
                    <Waypoint onEnter={() => handleWaypointIn('multi-text')}>         
                        <div id="multi-text">                     
                            <h2>MULTILINGUAL WEB DEVELOPMENT</h2>
                            <p>During my time at Wecreate I have gained much experience in creating multilingual websites – especially in wordpress and woocommerce. I can also add multilingual functionality to custom solutions.</p>
                            <p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
                        </div>    
                    </Waypoint>                 
                </div>

                <div className="gray-bg">
                    <Waypoint onEnter={() => handleWaypointIn('react-text')}>         
                        <div id="react-text">  
                            <h2>GATSBY/NEXT.JS WEB DEVELOPMENT</h2>

                            <p>For those wanting an exceptionally quick site and a modern approach then I recommend Wordpress CMS backend and either Gatsby or Next.js Frontend - both written in React. This is called a headless CMS which means the CMS is decoupled from the frontend layout. The benefits are that its more secure as no database calls and faster as it uses static files rather than the old dynamic sites that had to call on the database to get all it's information.</p>
                            <p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
                        </div>    
                    </Waypoint>      
                </div>
     
                <div className="white-bg">
                    <Waypoint onEnter={() => handleWaypointIn('cutom-text')}>         
                        <div id="cutom-text">                  
                            <h2>NEXT.JS/RUBY ON RAILS CUSTOM WEB DEVELOPMENT</h2>
                            <p>If you require more custom web development I can also help you with that. Rather than using an Open Source CMS (such as Wordpress or Drupal) I can build everything myself using either Ruby on Rails or Next.js.</p>

                            <p>Custom Web Development is used more for non-content sites. Such as IT helpdesks, Dasboard sites or any internal systems that don't fit the traditional website approach. With custom sites you get the functionality just as you require it but the trade-off is it can take longer and therefore more expensive.</p>
                            <p>Want to know more? Then please <Link to="#get-in-touch">Get in touch</Link>.</p>
                        </div>    
                    </Waypoint>       
                </div>
     
            </section>

            <ContactSection/>                               
        </Layout> 
    </div>
  )
}

export default WebDevelopment